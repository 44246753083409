// css
import './main.scss';

// js
import './carousels/carousels';
import './cta/cta';
import './forms/custom-dropdown';
import './site-header/site-header';
import { collapseSection, expandSection } from './misc/toggleHeight';
import SVGInject from '@iconfu/svg-inject';
import 'objectFitPolyfill';

//IE11 .closest polyfill
if (!Element.prototype.matches) {
	Element.prototype.matches =
		Element.prototype.msMatchesSelector ||
		Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
	Element.prototype.closest = function (s) {
		var el = this;

		do {
			if (Element.prototype.matches.call(el, s)) return el;
			el = el.parentElement || el.parentNode;
		} while (el !== null && el.nodeType === 1);
		return null;
	};
}

function init() {
	console.log('js init');

	SVGInject(document.querySelectorAll("img.injectable"));

	// For IE11
	window.objectFitPolyfill();

	test();
}

document.addEventListener('DOMContentLoaded', init);

function test() {
	var searchBtn = document.querySelector('.tech-library-search .btn.test-only');
	var collapseElement = document.querySelector('.collapsible');
	var searchResults = document.querySelector('.tech-library-search__results');

	if (searchBtn != null) {
		searchBtn.addEventListener('click', function (e) {
			e.preventDefault();

			if (!collapseElement.dataset.collapsed) {
				collapseSection(collapseElement);
				searchResults.classList.remove('hide');
			}
		});
	}
}