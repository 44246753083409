function customDropdown(){
	var dd = document.querySelectorAll('.custom-dropdown__title');

	dd.forEach(el => {
		el.addEventListener('click', toggleActive);
	});
}

function toggleActive(e){
	var target = e.target;
	var tp = target.closest('.dropdown');
	
	if (tp.classList.contains('active')){
		tp.classList.remove('active');
		target.setAttribute('aria-expanded', false);
	}
	else {
		tp.classList.add('active');
		target.setAttribute('aria-expanded', true);
	}
	
}

customDropdown();