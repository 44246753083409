function fixedNavOnScrollOld(){
	var primaryNav = document.querySelector('.navbar');
	var secondaryNav = primaryNav.querySelector('.navbar-secondary');
	var primaryNavHeight = primaryNav.scrollHeight;

	document.addEventListener('scroll', function () {
		if (document.body.scrollTop > primaryNavHeight || document.documentElement.scrollTop > primaryNavHeight) {
			primaryNav.classList.add('navbar-fixed-top');
			document.querySelector('body').style.paddingTop = primaryNavHeight + 'px';

			//mobile
			if (window.innerWidth <= '768') {
				secondaryNav.classList.add('hide');
			}
		} else {
			primaryNav.classList.remove('navbar-fixed-top');
			document.querySelector('body').style.paddingTop = '0';

			//mobile
			if (window.innerWidth <= '768') {
				secondaryNav.classList.remove('hide');
			}
		}
	});
}

function fixedNavOnScroll() {
	var primaryNav = document.querySelector('.navbar');
	var secondaryNav = primaryNav.querySelector('.navbar-secondary');
	var primaryNavHeight = primaryNav.scrollHeight;

	document.querySelector('body').style.paddingTop = primaryNavHeight + 'px';

	document.addEventListener('scroll', function () {
		if (document.body.scrollTop > primaryNavHeight || document.documentElement.scrollTop > primaryNavHeight) {
			//mobile
			if (window.innerWidth <= '768') {
				secondaryNav.classList.add('hide');
			}
		} else {
			//mobile
			if (window.innerWidth <= '768') {
				secondaryNav.classList.remove('hide');
			}
		}
	});
}

fixedNavOnScroll();