import {setWithExpiry, getWithExpiry} from '../misc/localStorage';

function alertBanner(){
	var navAlertBanner = document.querySelector('.nav-alert-banner');
	var navAlertClose = navAlertBanner !== null ? navAlertBanner.querySelector('.nav-alert-banner__closeBtn') : null;
	const now = new Date();

	console.log(getWithExpiry('alertBanner'), now.getTime());

	if (navAlertBanner !== null){
		if (getWithExpiry('alertBanner') == 'closed') {
			//$('.privacy-banner').css('display', 'inherit');
			navAlertBanner.style.display = 'none';
		} else {
			navAlertBanner.style.display = 'inherit';
		}

		navAlertClose.addEventListener('click', function (e) {
			e.preventDefault();

			navAlertBanner.style.display = 'none';
			setWithExpiry('alertBanner', 'closed', 5000);
		});
	}	
}

alertBanner();