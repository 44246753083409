//import Splide from '@splidejs/splide';

function HeroHomepage() {
	var elms = document.querySelectorAll('.hero--homepage.hero--main');
	
	for (var i = 0; i < elms.length; i++) {
		new Splide(elms[i], {
			type: 'loop',
			focus: 'center',
			arrows: false,
			perPage: 1,
			cover: false,
			height: '45rem',
			classes: {
				pagination: 'splide__pagination splide__pagination--vertical'
			},
			autoplay: true,
			pauseOnHover: true,
			interval: 6000,
			breakpoints: {
				// 1200: {
				// 	height: '45rem',
				// },
				// 1024: {
				// 	height: '45rem',
				// },
				// 768: {
				// 	height: '38rem'
				// },
				// 400: {
				// 	height: '25rem'
				// }
			}
		}).mount();
	}
}

function EventCarouselHome() {
	var elms = document.querySelectorAll('.events.splide');

	for (var i = 0, len = elms.length; i < len; i++) {
		new Splide(elms[i], {
			type: 'loop',
			focus: 'center',
			arrows: false,
			perPage: 1,
			cover: true,
			autoplay: true,
			pauseOnHover: true,
			interval: 6000,

			//breakpoints: {
			//	1200: {
			//		perPage: 3.5
			//	},
			//	1024: {
			//		perPage: 3
			//	},
			//	768: {
			//		perPage: 1.5
			//	},
			//	400: {
			//		perPage: 1.2
			//	}
			//}
		}).mount();
	}
}

function FeaturedProducts() {
	var elms = document.querySelectorAll('.featured-product');
	for (var i = 0, len = elms.length; i < len; i++) {
		new Splide(elms[i], {
			type: 'loop',
			focus: 'center',
			arrows: false,
			perPage: 1,
			cover: true,
			height: '45rem',
			autoplay: true,
			pauseOnHover: true,
			interval: 6000,
			classes: {
				pagination: 'splide__pagination splide__pagination--vertical'
			},
			breakpoints: {
				1024: {
					height: '45rem',
				},
				768: {
					height: 'auto',
				}
			}
		}).mount();
	}
}

HeroHomepage();
EventCarouselHome();
FeaturedProducts();